import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { AppProvider } from '@shopify/polaris';
import { Provider } from '@shopify/app-bridge-react';

import Layout from './layout';
import Auth from './screens/auth/';
import Install from './screens/auth/install';
import Paid from './screens/auth/paid';
import ErrorPage from './screens/auth/error';

import { login, logout, setTab } from './actions';

// layout routes
import Navigation from './screens/navigation';

class App extends React.Component {

    requireAuth = async () => {
        return this.props.shop.loggedIn;
    }

    componentDidMount = () => {
        this.props.setTab(0);
    }
    
    render() {
        try {        
            return (
                <Router>
                    <Switch>
                        <Route path="/apps/" render={() => {
                            const config = {
                                apiKey: process.env.REACT_APP_API_KEY, 
                                shopOrigin: this.props.shop.info.shop,
                                forceRedirect: process.env.REACT_APP_FORCE_REDIRECT
                            };
                            return this.requireAuth() && this.props.shop.info ? (
                                <AppProvider>
                                    <Provider config={config}>
                                        <Layout>
                                            <Route exact path="/apps/" component={Navigation}/>
                                        </Layout>
                                    </Provider>
                                </AppProvider>
                            ) : (
                                <Redirect to="/logout"/>
                            )
                        }}/>
                        <Route exact path="/auth" component={Auth} />
                        <Route exact path="/install" component={Install} />
                        <Route exact path="/paid" component={Paid} />
                        <Route exact path="/error" component={ErrorPage} />
                        <Route component={ErrorPage} />
                    </Switch>
                </Router>
            )
        } catch(e) {
            console.log("Error", e.message);
            return null;
        }
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    login,
    logout,
    setTab
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);