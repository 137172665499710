import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
function Layout(props) {

    return (
        <div>
            {props.children}
        </div>
    )
}

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(withRouter(Layout));