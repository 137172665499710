export function login(info) {
    return {
        type: "LOGIN",
        payload: {
            info
        }
    }
}

export function logout() {
    return {
        type: "LOGOUT",
        payload: {
            info: null
        }
    }
}

export function setPath(path) {
    return {
        type: "SET_PATH",
        payload: path
    }
}

export function setTab(tab) {
    return {
        type: "SET_TAB",
        payload: tab
    }
}

// normal functions

const API_URL = process.env.REACT_APP_API_URL;

export function checkAuth(query) {
    return new Promise((resolve, reject) => {
        let parameters = [];
        for (var key in query) {
            parameters.push(key + '=' + query[key]);
        }

        let params = parameters.sort().join(query.hmac ? '&' : '');
        const endpoint = `${API_URL}/auth?${params}`;
    
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });

    });
}

export function install(query) {
    return new Promise((resolve, reject) => {
        const { timestamp, shop, hmac, code, state } = query;
        const endpoint = `${API_URL}/auth/install?shop=${shop}&hmac=${hmac}&timestamp=${timestamp}&code=${code}&state=${state}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function checkPlan(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/auth/plan?id=${id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function confirmPlan(query) {
    return new Promise((resolve, reject) => {
        const { charge_id, id } = query;
        const endpoint = `${API_URL}/auth/confirm?id=${id}&charge_id=${charge_id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function updateDarkMode(data) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/apps/dark`;

        fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}