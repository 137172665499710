import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var config = {
    apiKey: "AIzaSyAmdgwXp4nF6DRx8yvuyJj_P6b7fSBMQ7w",
    authDomain: "boxup-enhance.firebaseapp.com",
    databaseURL: "https://boxup-enhance.firebaseio.com",
    projectId: "boxup-enhance",
    storageBucket: "boxup-enhance.appspot.com",
    messagingSenderId: "830387021684",
    appId: "1:830387021684:web:819f9559c482cd0f3eee21",
    measurementId: "G-V1FNG5D4C4"
};

export const fb = !app.apps.length ? app.initializeApp(config) : app.app();
export const db = app.firestore();