import React, { useCallback, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Navigation as Nav, Frame } from '@shopify/polaris';
import { setTab } from './../actions/';
import Home from './home/';
import { ReportsMajorMonotone, ClockMinor, EnvelopeMajorTwotone } from '@shopify/polaris-icons';

function Navigation(props) {

    const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

    const toggleMobileNavigationActive = useCallback(
        () =>
          setMobileNavigationActive(
            (mobileNavigationActive) => !mobileNavigationActive,
          ),
        [],
    );

    const navItems = [
        {
            onClick: () => props.setTab(0),
            label: 'Dashboard',
            icon: ReportsMajorMonotone,
            selected: props.shop.tab === 0,
            contextControl: <Home/>
        },
        {
            onClick: () => props.setTab(1),
            label: 'History',
            icon: ClockMinor,
            selected: props.shop.tab === 1,
            contextControl: null
        }
    ];

    const navigationMarkup = <Nav location="/apps/">
        <Nav.Section
            items={navItems}
        />
        <Nav.Section
            items={[
            {
                onClick: () => props.setTab(4),
                label: 'Support',
                icon: EnvelopeMajorTwotone,
                selected: props.shop.tab === 4,
            },
            ]}
            separator
        />
    </Nav>;

    useEffect(() => {
        console.log("here")
    }, []);

    return <Frame
        navigation={navigationMarkup}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
    >
        {navItems[props.shop.tab].contextControl}
    </Frame>
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    setTab
}

export default connect(
    mapStateToProps,
    actions
)(withRouter(Navigation));