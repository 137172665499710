import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactQueryParams from 'react-query-params';
import { login, checkAuth } from './../../actions/';
import { fb } from './../../actions/settings';
import ErrorPage from './../auth/error';
import { LoadingPage } from './../../screens/loading';

class Auth extends ReactQueryParams {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            info: null,
            installUrl: null,
            hasAuth: false,
            id: ''
        }
    }

    componentDidMount = async () => {
        try {
            let res = await checkAuth(this.queryParams);
            this.props.login(res.user);
            this.setState({
                loading: false,
                info: res.user,
                hasAuth: res.has_auth,
                installUrl: res.install_url,
                id: res.unique
            });
        } catch(e) {
            console.log(e);
            this.setState({
                loading: false,
                hasAuth: false
            });
        }
    }

    render() {
        let { history } = this.props;
        let { hasAuth, installUrl, info, loading } = this.state;
        if (!loading) {
            if (hasAuth) {
                if (installUrl) {
                    window.location.href = installUrl;
                } else {
                    try {
                        fb
                        .auth()
                        .signInWithEmailAndPassword(info.email, this.state.id)
                        .then((user) => {
                            if (user) {
                                history.push("/apps/");
                            }
                        })
                        .catch((err) => console.log(err));
                    } catch(e) {
                        console.log(e);
                        return <ErrorPage/>;
                    }
                }
                return null;
            } else {
                return <ErrorPage/>
            }
        } else {
            return <LoadingPage shop=""/>
        }
    }
}

const mapStateToProps = state => {
    return state;
}
  
const mapDispatchToProps = {
    login
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Auth));