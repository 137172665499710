import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { db } from './../../actions/settings';
import { login, setPath } from './../../actions/';
import { Page } from '@shopify/polaris';
import { Loading } from './loading';

function Home(props) {

    const [state, setState] = useState({
        loading: false
    })

    useEffect(() => {
        props.setPath("Home");
        if (window.$zoho.salesiq.visitor) 
            window.$zoho.salesiq.visitor.email(props.shop.info.email);
        //setState({...state, loading: false});
    }, []);

    let { loading } = state;
    if (loading) {
        return <Loading/>
    } else {
        return <Page
            title="Two-Factor Authentication"
            subtitle="Secure your customers' login process."
            separator
            fullWidth
        >
            <p>Home Page</p>
        </Page>
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    login,
    setPath
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Home));